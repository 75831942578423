
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <!-- Search -->
              <div class="col-sm-12 col-md-12">
                <div class="row align-items-center">
                  <div class="col-sm-4 col-md-4">
                    <div class="mb-3 position-relative">
                      <label class="d-inline align-items-center"> สาขา:</label>
                      <multiselect
                        v-model="branchIdSearch"
                        :options="localData"
                        label="nameTh"
                        :show-labels="false"
                      >
                      </multiselect>
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-3">
                    <div class="mb-3 position-relative">
                      <label class="d-inline align-items-center">
                        เรื่อง :</label
                      >
                      <b-form-input
                        v-model="filter.subject"
                        type="search"
                        placeholder=""
                        class="form-control"
                        @keyup.enter="handleSearch"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="col-md-5 col-sm-6 float-end text-end">
                    <btnComponent @click="handleSearch" changeStyle="search">
                    </btnComponent
                    >&nbsp; &nbsp; &nbsp;
                    <router-link
                      :to="{ name: 'contactMessageForm' }"
                      class="btn btn-primary float-end"
                    >
                      <i class="mdi mdi-plus me-1"></i>สร้าง
                    </router-link>
                  </div>
                </div>
              </div>
              <!-- End search -->
            </div>

            <div class="row mt-4">
              <!-- Search -->
              <div class="col-sm-12 col-md-12">
                <div class="row align-items-center">
                  <div class="col-sm-12 col-md-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-block align-items-center">
                        แสดงผล
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                          @input="handlePageChange"
                        ></b-form-select
                        >&nbsp; รายการ
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <b-skeleton-wrapper :loading="loading">
              <template #loading>
                <b-skeleton-table
                  :rows="5"
                  :columns="6"
                  :table-props="{ bordered: false, striped: true }"
                  animation="throb"
                ></b-skeleton-table>
              </template>
              <div class="table-responsive mb-0">
                <tableData :fields="fields" :items="rowContact">
                  <template
                    #cell(index)="rowContact"
                    v-if="this.currentPage > 1"
                  >
                    {{
                      rowContact.index + 1 + (currentPage * perPage - perPage)
                    }}
                  </template>
                  <template #cell(index)="rowContact" v-else>
                    {{ rowContact.index + 1 }}
                  </template>
                  <template #cell(createdAt)="rowContact">
                    {{ date(rowContact.item.createdAt) }}
                  </template>

                  <template #cell(selected)="{ rowSelected }">
                    <template v-if="rowSelected">
                      <span aria-hidden="true">&check;</span>
                      <span class="sr-only">Selected</span>
                    </template>
                    <template v-else>
                      <span aria-hidden="true">&nbsp;</span>
                      <span class="sr-only">Not selected</span>
                    </template>
                  </template>

                  <!-- edit table -->
                  <template v-slot:cell(action)="rowContact">
                    <router-link
                      class="px-2 text-primary"
                      v-b-tooltip.hover
                      title="Edit"
                      :to="{
                        name: 'detailContact',
                        params: {
                          groupContactId: rowContact.item.groupContactIdBase64,
                        },
                      }"
                    >
                      <i class="uil uil-eye font-size-18"></i>
                    </router-link>

                    <!-- <a
                      class="px-2 text-danger"
                      v-b-tooltip.hover
                      title="Delete"
                      @click="ConfirmDelUser(rowUser.item.cusId)"
                    >
                      <i class="uil uil-trash-alt font-size-18"></i>
                    </a> -->
                  </template>

                  <!-- End edit table -->
                </tableData>
              </div>
              <div class="row">
                <span
                  v-if="this.totalRecord === 0"
                  class="text-center font-size-16"
                  >ไม่พบรายการ</span
                >
                <div class="col" v-if="this.totalRecord > 0">
                  <div class="col">
                    หน้า {{ currentPage }} จาก {{ this.totalPage }} ทั้งหมด
                    {{ totalRecord }} รายการ
                  </div>

                  <div class="col">
                    <div
                      class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-end
                      "
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="totalRecord"
                          :per-page="perPage"
                          @change="handleChangePage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
                <br />
                <hr />
                <br />
                <!-- <p>
                  <b-button size="sm" @click="selectAllRows"
                    >เลือกทั้งหมด</b-button
                  >
                  |
                  <b-button size="sm" @click="clearSelected"
                    >ล้างการเลือก</b-button
                  >
                </p> -->
              </div>
            </b-skeleton-wrapper>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
// import DatePicker from "vue2-datepicker";
import btnComponent from "@/components/btnComponent";
import tableData from "@/components/tablecomponent";
import Multiselect from "vue-multiselect";
// import branchComponent from "@/components/branchComponent.vue";
/**
 * Advanced-table component
 */
export default {
  page: {
    title: appConfig.groupContacts,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    btnComponent,
    tableData,
    Multiselect,
    // branchComponent,
    // DatePicker
  },
  data() {
    return {
      title: appConfig.groupContacts,
      items: [
        {
          text: "ลูกค้าสัมพันธ์",
          active: true,
        },
        {
          text: appConfig.groupContacts,
          active: true,
        },
      ],
      localDataBranch: [], //เป็น-array Id
      localData: [],

      cusType: [
        {
          cusType: "P",
          CustypeName: "บุคคล",
        },
        {
          cusType: "B",
          CustypeName: "นิติบุคคล",
        },
      ],
      branchIdSearch: "",
      from: [],
      CusType: "",
      CustypeName: "",
      groupContactId: this.$route.params.groupContactId,
      totalPage: "",
      loading: undefined,
      total_pages: "",
      page: "",
      per_page: "",
      totalRecord: "",
      // rowData: [], //? Data ที่ต่อ API
      rowContact: [],
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50, 100],
      filter: [
        {
          subject: "",
          createdAt: "",
        },
      ],
      subject: "",
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      paginatedData: "",

      selectedUsers: [],
      isSelectedAll: false,

      selectMode: "multi",
      selected: [],

      fields: [
        {
          key: "index",
          label: "ลำดับ",
        },
        {
          key: "createdAt",
          label: "วัน-เวลา ที่ทำรายการ",
          sortable: true,
        },
        {
          key: "subject",
          label: "เรื่อง",
          sortable: true,
        },
        {
          key: "createdBy",
          sortable: true,
          label: "ผู้ทำรายการ",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
        {
          key: "action",
          label: appConfig.action,
        },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.rowData.length;
    },
    //
  },
  mounted() {
    // Set the initial number of items
  },

  created() {
    this.getLocalData();
    this.getData();
  },
  methods: {
    date(data) {
      var m = new Date(data);
      var dateString =
        m.getUTCFullYear() +
        "/" +
        ("0" + (m.getUTCMonth() + 1)).slice(-2) +
        "/" +
        ("0" + m.getUTCDate()).slice(-2) +
        " " +
        ("0" + m.getUTCHours()).slice(-2) +
        ":" +
        ("0" + m.getUTCMinutes()).slice(-2) +
        ":" +
        ("0" + m.getUTCSeconds()).slice(-2);
      return dateString;
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });

      this.localDataBranch = localDataBranch;
      // console.log(this.localDataBranch);
    },
    getData: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/group-contact", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.localDataBranch,
            subject: this.filter.subject,
            createdAt: this.filter.createdAt,
          },
        })
        .then((response) => {
          this.rowContact = response.data.data;
          const per_Page = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / per_Page);
          // console.log(response);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    handleSearch() {
      if (this.branchIdSearch === "") {
        this.localDataBranch;
      } else {
        this.localDataBranch = [this.branchIdSearch.branchId];
      }
      this.getData();
    },

    ConfirmDelUser(rowContact) {
      Swal.fire({
        title: "ต้องการลบรายการนี้ใช่หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "ลบ",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.deleteUser(rowContact);
        }
      });
    },
    deleteUser: function (rowContact) {
      this.userData = rowContact;
      this.loading = true; //skeleton true
      useNetw
        .delete("api/customer/delete", {
          data: {
            cusId: this.userData,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
          this.getData();
        });
    },

    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.rowUser = filteredItems.length;
      this.currentPage = 1;
    },

    onRowSelected(rowData) {
      this.selected = rowData;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
  },
  middleware: "authentication",
};
</script>

